const possibleAuthCookies = [
  `sb-127-auth-token`,
  `sb-auth-token`,
  'sb-bbqijaqjrahmakotxpqo-auth-token',
  'sb-bbqijaqjrahmakotxpqo-auth-token.0',
  'sb-bbqijaqjrahmakotxpqo-auth-token.1',
  'sb-auth-token',
  'sb-auth',
];

// Helper function to get cookie by name
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// Helper function to remove cookie by name
function removeCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.$router.beforeEach((to, from, next) => {
    // Only run this on client-side
    if (process.client && to.path === '/') {
      console.log('[Plugin] Current route is the root path (/)');

      // Find the first existing authentication cookie
      const authCookie = possibleAuthCookies.find((cookieName) => {
        return getCookie(cookieName);
      });

      if (authCookie) {
        console.log('[Plugin] Authentication cookie found:', authCookie);

        // Get the redirect path from the cookie
        const redirectPath = getCookie('sb-redirect-path') || '/app/documents';
        removeCookie('sb-redirect-path');

        console.log('[Plugin] Redirecting to:', redirectPath);

        next(redirectPath);
        return;
      }
    }
    next();
  });
});
