export async function addDocument(document) {
  const supabase = useSupabaseClient();
  const user = useSupabaseUser();

  // Check if the user is logged in
  if (!user.value) {
    throw new Error('User is not logged in');
  }

  // More thorough sanitization of the filename
  let sanitizedName = document.name
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/Ä/g, 'Ae')
    .replace(/Ö/g, 'Oe')
    .replace(/Ü/g, 'Ue')
    .replace(/ß/g, 'ss')
    // Replace spaces, commas and other special characters
    .replace(/[,\s]/g, '_')
    // Remove or replace any other non-alphanumeric characters except .-_
    .replace(/[^a-zA-Z0-9._-]/g, '')
    // Replace multiple consecutive underscores with a single one
    .replace(/_+/g, '_');

  // Create a sanitized file path
  const filePath = `${user.value.id}/${sanitizedName}`;
  console.log('Uploading document to:', filePath);

  return supabase.storage
    .from('private-docs')
    .upload(filePath, document)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error('Error uploading document:', error, error.message);
      throw error;
    });
}

export async function fetchDocuments(page = 1, limit = 50) {
  const supabase = useSupabaseClient();

  const start = (page - 1) * limit;
  const end = start + limit - 1;

  return supabase
    .from('documents_with_storage_path')
    .select(
      `id, created_at, updated_at, deleted_at, original_created_at, original_name, hints, storage_object_path, tags, sender_id, sender, processing_state`,
      {count: 'exact'}
    )
    .is('deleted_at', null)
    .order('original_created_at', {ascending: false})
    .range(start, end)
    .then(response => ({
      documents: response.data || [],
      count: response.count || 0
    }))
    .catch(error => {
      console.error('Error fetching documents:', error, error.message);
      throw error;
    });
}

export async function fetchDocumentsBySender(senderId, page = 1, limit = 50) {
  const supabase = useSupabaseClient();

  const start = (page - 1) * limit;
  const end = start + limit - 1;

  return supabase
    .from('documents_with_storage_path')
    .select(
      `id, created_at, updated_at, deleted_at, original_created_at, original_name, hints, storage_object_path, tags, sender_id, sender, processing_state`
    )
    .eq('sender_id', senderId)
    .is('deleted_at', null)
    .order('original_created_at', {ascending: false})
    .range(start, end)
    .then(response => ({
      documents: response.data || [],
      count: response.count || 0
    }))
    .catch(error => {
      console.error('Error fetching documents by sender:', error);
      throw error;
    });
}

export async function fetchDocumentsByTag(tagId, page = 1, limit = 50) {
  const supabase = useSupabaseClient();

  const start = (page - 1) * limit;
  const end = start + limit - 1;

  return supabase
    .from('documents_with_storage_path')
    .select(
      `id, created_at, updated_at, deleted_at, original_created_at, original_name, hints, storage_object_path, tags: tags->id, sender_id, sender, processing_state`
    )
    .contains('tags', {id: tagId})
    .is('deleted_at', null)
    .order('original_created_at', {ascending: false})
    .range(start, end)
    .then(response => ({
      documents: response.data || [],
      count: response.count || 0
    }))
    .catch(error => {
      console.error('Error fetching documents by sender:', error);
      throw error;
    });
}

export async function fetchTrashDocuments() {
  const supabase = useSupabaseClient();

  return supabase
    .from('documents_trash')
    .select('*')
    .limit(50)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching trashed documents:', error, error.message);
      throw error;
    });
}

export function subscribeToDocumentUpdates(callback) {
  console.log('Setting up document subscription...');
  const supabase = useSupabaseClient();

  const channel = supabase.channel('document_changes');

  const subscription = channel
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'documents'
      },
      payload => {
        console.log('Received document change:', payload);

        // Fetch the complete document data including relationsT
        supabase
          .from('documents_with_storage_path')
          .select('*')
          .eq('id', payload.new.id)
          .single()
          .then(({data, error}) => {
            if (error) {
              console.error('Error fetching updated document:', error);
              return;
            }

            callback({
              eventType: payload.eventType,
              new: data, // Use the complete document data
              old: payload.old
            });
          });
      }
    )
    .subscribe(status => {
      console.log('Subscription status:', status);

      if (status === 'SUBSCRIBED') {
        console.log('Successfully subscribed to document changes');
      } else if (status === 'CLOSED') {
        console.log('Subscription closed');
      } else if (status === 'CHANNEL_ERROR') {
        console.error('Channel error:', status);
      }
    });

  return subscription;
}

export async function unsubscribeFromDocumentUpdates(subscription) {
  console.log('unsubscribeFromDocumentUpdates called');
  if (subscription) {
    await subscription.unsubscribe();
  }
}

export async function subscribeToTrashDocumentUpdates(callback) {
  console.log('Setting up trash document subscription...');
  const supabase = useSupabaseClient();

  const channel = supabase.channel('trash_document_changes');

  const subscription = channel
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'documents'
      },
      payload => {
        console.log('Received trash document change:', payload);

        // Fetch the complete document data including relations
        supabase
          .from('documents_with_storage_path')
          .select('*')
          .eq('id', payload.new.id)
          .single()
          .then(({data, error}) => {
            if (error) {
              console.error('Error fetching updated trash document:', error);
              return;
            }

            callback({
              eventType: payload.eventType,
              new: data,
              old: payload.old
            });
          });
      }
    )
    .subscribe(status => {
      console.log('Trash subscription status:', status);

      if (status === 'SUBSCRIBED') {
        console.log('Successfully subscribed to trash document changes');
      } else if (status === 'CLOSED') {
        console.log('Trash subscription closed');
      } else if (status === 'CHANNEL_ERROR') {
        console.error('Trash channel error:', status);
      }
    });

  return subscription;
}

export async function unsubscribeFromTrashDocumentUpdates(subscription) {
  if (await subscription) {
    await subscription.unsubscribe();
  }
}

export async function searchDocumentsOnServer(query) {
  const supabase = useSupabaseClient();

  // If the query contains spaces, concatenate the words with | to perform an OR search
  query = query.replace(/\s+/g, ' | ');

  const {data, error} = await supabase
    .from('documents_with_storage_path')
    .select(
      `id, created_at, updated_at, original_created_at, original_name, hints, storage_object_path, tags, sender_id, sender, processing_state`
    )
    .is('deleted_at', null)
    .textSearch('content', query, {type: 'websearch'})
    .limit(50);
  if (error) {
    console.error('Error searching documents:', error);
    throw error;
  } else {
    return data;
  }
}

export async function fetchDocumentOnServer(documentId) {
  const supabase = useSupabaseClient();

  const {data, error} = await supabase
    .from('documents_with_storage_path')
    .select(
      `id, created_at, updated_at, original_created_at, original_name, name, hints, storage_object_path, tags, sender_id, sender, processing_state`
    )
    .eq('id', documentId)
    .is('deleted_at', null)
    .single();

  if (error) {
    console.error('Error fetching document:', error);
    throw error;
  } else {
    return data;
  }
}

export async function updateDocumentOnServer(document) {
  const supabase = useSupabaseClient();

  if (!document || !document.id || !document.original_name) {
    throw new Error('Invalid document or missing required fields');
  }

  const {data, error} = await supabase
    .from('documents')
    .update({
      original_name: document.original_name,
      original_created_at: document.original_created_at,
      sender_id: document.sender_id
    })
    .eq('id', document.id)
    .select();

  if (error) {
    console.error('Error updating document:', error);
    throw error;
  }

  return data;
}

export async function softDeleteDocumentOnServer(documentId) {
  const supabase = useSupabaseClient();

  // Check if parameter is set
  if (!documentId) {
    throw new Error('Document ID is required');
  }

  // Update the document to set the deleted_at timestamp
  const {error} = await supabase
    .from('documents')
    .update({deleted_at: new Date()})
    .eq('id', documentId);

  if (error) {
    console.error('Error deleting document:', error);
    throw error;
  }
}

export async function hardDeleteDocumentOnServer(documentId) {
  const supabase = useSupabaseClient();

  // Check if parameter is set
  if (!documentId) {
    throw new Error('Document ID is required');
  }

  // Delete the document from the database
  const {error} = await supabase.from('documents').delete().eq('id', documentId);

  if (error) {
    console.error('Error deleting document:', error);
    throw error;
  }
}

export async function restoreDocumentOnServer(documentId) {
  const supabase = useSupabaseClient();

  // Check if parameter is set
  if (!documentId) {
    throw new Error('Document ID is required');
  }

  // Update the document to remove the deleted_at timestamp
  const {error} = await supabase.from('documents').update({deleted_at: null}).eq('id', documentId);

  if (error) {
    console.error('Error restoring document:', error);
    throw error;
  }
}

export async function searchTags(query) {
  const supabase = useSupabaseClient();

  const {data, error} = await supabase
    .from('tags')
    .select('id, name, color, created_by')
    .ilike('name', `%${query}%`)
    .order('name', {ascending: true})
    .limit(50);

  if (error) throw error;
  return data;
}

export async function createTag(tagData) {
  const supabase = useSupabaseClient();
  const user = useSupabaseUser();

  if (!user.value) throw new Error('User not authenticated');

  const {data, error} = await supabase
    .from('tags')
    .insert({
      name: tagData.name,
      color: tagData.color || 'gray',
      created_by: user.value.id
    })
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function addTagToDocument(documentId, tag) {
  const supabase = useSupabaseClient();

  let tagToAdd = tag;

  // If the tag doesn't have an ID, it's a new tag that needs to be created
  if (!tag.id) {
    tagToAdd = await createTag(tag);
  }

  // Now, associate the tag with the document
  const {error} = await supabase
    .from('document_tags')
    .insert({document_id: documentId, tag_id: tagToAdd.id});

  if (error) throw error;

  return tagToAdd;
}

export async function removeTagFromDocument(documentId, tagId) {
  const supabase = useSupabaseClient();

  const {error} = await supabase
    .from('document_tags')
    .delete()
    .match({document_id: documentId, tag_id: tagId});

  if (error) throw error;
}

export async function downloadDocument(filePath) {
  const supabase = useSupabaseClient();

  const {data, error} = await supabase.storage.from('private-docs').download(filePath);
  if (error) {
    console.error('Error downloading document:', error);
    throw error;
  } else {
    return data;
  }
}

/**
 * Get a signed URL for a private document
 * @param {*} filePath
 * @returns {Promise<string>} The signed URL
 */
export async function getSignedUrlForDocument(filePath) {
  const supabase = useSupabaseClient();

  return supabase.storage.from('private-docs').createSignedUrl(filePath, 60);
}

export async function updateDocumentTags(document) {
  const supabase = useSupabaseClient();

  // Fetch current tags for the document
  const {data: currentTags, error: fetchError} = await supabase
    .from('document_tags')
    .select('tag_id')
    .eq('document_id', document.id);

  if (fetchError) throw fetchError;

  const currentTagIds = new Set(currentTags.map(t => t.tag_id));
  const newTagIds = new Set(document.tags.map(t => t.id));

  // Determine tags to add and remove
  const tagsToAdd = document.tags.filter(t => !currentTagIds.has(t.id));
  const tagIdsToRemove = [...currentTagIds].filter(id => !newTagIds.has(id));

  // Remove tags
  for (const tagId of tagIdsToRemove) {
    await removeTagFromDocument(document.id, tagId);
  }

  // Add new tags
  for (const tag of tagsToAdd) {
    await addTagToDocument(document.id, tag);
  }
}
